.howto-box {
  max-width: 1400px;
}
.howto-box img{
  max-width: 800px;
}
.contactus-box {
  max-width: 1600px;
}
.awsui .awsui-text-large {
  font-size: 4.4rem;
  line-height: 5rem;
  font-weight: 300;
  padding-top: 0;
  padding-bottom: 1rem;
}