.card_image {
  width: 90%;
  // max-width: 800px;
  margin-left: 5%;
  margin-right: 5%;
  height: auto;
}
.avatar {
  // vertical-align: middle;
  background-size: cover;

  background-position: top center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.badge_contents_type{
  margin-right: 0.2em;
  margin-bottom: 0.2em;
}
.box_description{
  max-height: 100px;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis; 
  // white-space: nowrap;
}

.box_detail_description{
  overflow:hidden;
  overflow-wrap: break-word;
  // text-overflow: ellipsis; 
  // white-space: nowrap;
  white-space:pre-line;
}

.image__placeholder:before {
  content: 'X';
  width: 95%;
  // max-width: 800px;
  // margin-left: 5%;
  margin-right: 5%;
  display: block;
  background-color: var(--color-background-layout-main-3Bydyi, #f2f3f3);
  color: var(--color-background-container-content-3w9fMz, #ffffff);
  text-align: center;
  font-size: 200px;
  line-height: 200px;
  line-height: 200px;
}

// .input-container {
//   display: flex;
//   flex-wrap: wrap;
//   order: 0;
//   flex-grow: 10;
//   margin-right: 2rem;
//   width: 100%;
// }
.input-filter {
  min-width:30em;
}

._tools-filtering-content_48piw_20:not(#\9){
  max-width:none;
}